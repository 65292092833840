import * as React from "react"
import { graphql, PageProps, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { postSubtitle, postBody } from "./post.module.css"
import Layout from "../../components/layout"
// import { PostBodyType, SitedataType } from "../../interfaces";
/*
interface DataProps {
  post: PostBodyType,
  sitedata: SitedataType
} */

const Post = ({ data }) => {
  return (
    <Layout pageTitle={data.post.frontmatter.title}>
      <p className={postSubtitle}>
        Posted on {data.post.frontmatter.date} by {data.sitedata.siteMetadata.author} in{" "}
        <Link to={`/${data.post.frontmatter.cat}/`}>{data.post.frontmatter.cat}</Link>
      </p>
      <div className={postBody}>
        <MDXRenderer>{data.post.body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    post: mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        cat
      }
      body
    }
    sitedata: site {
      siteMetadata {
        author
      }
    }
  }
`

export default Post
